.sidebar-slider{
    background: #F9F9F9;
    border: 2px solid #EEEEEE;
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 375px;
    position:absolute;
    left: 125px;
    padding: 25px;
    z-index: 1051;
    top: 0;
    color:$heading-grey;
    height: 100%;
    overflow-y:auto;
    >h3{
        font-size: 30px;
    }

    h3,p{
        font-weight: $font-light;
    }

    i{
        color: #474747;
    }

    &.inside-component{
        left:-10px;
    }

    &.formEdited{
        .kpiEnterForm{
            .form-group{
        
                input{
                    color: rgba(78, 78, 78, 1);;
                }
            }    
        }
    }
    &__sub-heading{
        font-size: 25px;
    }
    &__sub-tagline{
        font-size: 15px;
        line-height: 22px;
        color: rgba(78, 78, 78, 0.5);
    }

    .top-left-btn{
        position: absolute;
        left: 15px;
        top: 15px;
        cursor: pointer;
        i{
            color:#474747 ;
        }
    }

    .enterKpiSlider_set.formEdited{
        .form-group{
        
            input{
                color: rgba(78, 78, 78, 1);;
            }
        }    
    }

    &_close{
        color: #474747;
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        z-index: 2;
    }
    .unitname{
        color:rgba(78, 78, 78, 0.5);
    }
    .form-group{
        text-align: left;

        input{
           color:rgba(78, 78, 78, 0.5);
        }
    }
    .btn-grad{
        border-radius: 10px;
    }

    // .react-datepicker__input-container{
    //     display: none;
    // }
    .react-datepicker-wrapper{
        display: block;
        i{
            cursor: pointer;
        }
    }
   
    .react-datepicker__navigation{
        top:8px;
    }

    &.enterKpiSlider{
        padding-bottom: 10px;
        padding-top: 20px;

        .react-datepicker-wrapper{
            width: auto;
        }

    }
    &_alert{
        position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 1;
    }

  
}
.kpiDatepicker_monthyear{
    margin-left: 24px;
    h3{
        font-size: 25px;
    }
    p{
        font-size: 12px;
    }
}

.kpiDatepicker_label{
    cursor: pointer;

}

.kpiDatepickerCalendar{
    .react-datepicker__triangle{
        display: none;
    }

}

//Kpi graph
.no-value{
    cursor: pointer;
}   
.value-absent{
    cursor: default;
}